import React, { useState, useContext, useEffect } from "react"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { graphql } from "gatsby"
import css from "@styled-system/css"
import {
  Section,
  Box,
  Button,
  Container,
  Flex,
  Text,
  PostCard,
  ProjectFilter,
  Select,
} from "components"
import {
  ActiveFilterProvider,
  ActiveFilterContext,
} from "../../contexts/project-filters"

const VerticalMarketsPage = ({ data }) => {
  const [currentPosition, setCurrentPosition] = useState(0)
  const [filterBranch, setFilterBranch] = useState("All Branches")
  const [visibleProjects, setVisibleProjects] = useState([])
  const size = 7
  const { activeFilters } = useContext(ActiveFilterContext)

  useEffect(() => {
    data.page.projects &&
      data.page.projects.length > 0 &&
      setVisibleProjects(
        data.page.projects
          .filter(project => {
            if (activeFilters.categories.length > 0) {
              return activeFilters.categories.every(filterCategory => {
                let foundCategory = false
                project.projectCategories.forEach(category => {
                  if (filterCategory === category.name) {
                    foundCategory = true
                  }
                })
                return foundCategory
              })
            } else {
              return true
            }
          })
          .filter(project => {
            if (filterBranch !== "All Branches") {
              if (project.branch.name === filterBranch) {
                return true
              } else {
                return false
              }
            } else {
              return true
            }
          })
      )
  }, [activeFilters, data.projects, filterBranch, data.page.projects])

  return (
    <>
      <HelmetDatoCms seo={data.page.seoMetaTags}>
        <meta name="keywords" content={data.page.seoKeywords} />
      </HelmetDatoCms>
      <Section
        image={
          data.page.heroMedia &&
          data.page.heroMedia.url &&
          data.page.heroMedia.url.indexOf(".mp4") === -1
            ? data.page.heroMedia.url
            : ""
        }
        video={
          data.page.heroMedia &&
          data.page.heroMedia.url &&
          data.page.heroMedia.url.indexOf(".mp4") !== -1
            ? data.page.heroMedia.url
            : undefined
        }
        title={data.page.heroTitle}
        subtitle={data.page.heroSubtitle}
        color="textReverse"
        bg="backgroundReverse"
        top={170}
      />
      <Box as="section" pt={4} pb={4}>
        <Container>
          <div css={css({ display: ["block", "flex"] })}>
            <div css={css({ flex: "0 1 70%", mt: [3, 4] })}>
              <ProjectFilter
                type={"categories"}
                filterNodes={data.projectCategories.nodes.sort((a, b) => {
                  if (a.name < b.name) {
                    return -1
                  } else if (a.name > b.name) {
                    return 1
                  } else {
                    return 0
                  }
                })}
              />
            </div>
            <div css={css({ flex: "0 1 30%", mt: [4, 0], mb: [4] })}>
              <Text
                children={"Filter by branch"}
                mt={0}
                fontFamily="condensed"
                fontSize={[4, 5]}
                css={css({ mt: 0, mb: 2 })}
              />
              <div>
                <Select
                  value={filterBranch}
                  onChange={event => {
                    setFilterBranch(event.target.value)
                  }}
                >
                  <option>All Branches</option>
                  {data.branches &&
                    data.branches.nodes
                      .sort((a, b) => {
                        if (a.name < b.name) {
                          return -1
                        } else if (a.name > b.name) {
                          return 1
                        } else {
                          return 0
                        }
                      })
                      .map((branch, index) => {
                        return (
                          <option key={`${branch}-${index}`}>
                            {branch.name}
                          </option>
                        )
                      })}
                </Select>
              </div>
            </div>
          </div>
        </Container>
      </Box>
      <Box bg="muted" position="relative" pb={[5, 6]}>
        <Container>
          <Flex pt={5} m={-3} flexWrap="wrap" css={css({ overflow: "hidden" })}>
            {visibleProjects.length === 0 && (
              <Text
                children={
                  "Sorry there aren't any projects to see with these filter settings, please refine your selections to see other projects."
                }
                mt={[3, 4]}
                fontFamily="condensed"
                fontSize={[6, 7]}
                css={css({ mt: 4, mb: 2, ml: 3, maxWidth: ["100%", "70%"] })}
              />
            )}
            {visibleProjects
              .slice(0, size * (currentPosition + 1))
              .map((project, index) => (
                <PostCard
                  width={
                    (index % 5 === 0 || index % 5 === 1) && [
                      "100%",
                      null,
                      1 / 2,
                      1 / 2,
                    ]
                  }
                  image={project.image && project.image.url}
                  date={project.year}
                  title={project.title}
                  subtitle={project.subtitle}
                  link={"/vertical-markets/" + project.url + "/"}
                  key={"project" + project.url + index}
                />
              ))}
          </Flex>
          {visibleProjects.length > 0 &&
            visibleProjects.length > size * (currentPosition + 1) && (
              <Flex justifyContent="center" mt={5}>
                <Button
                  children={"See more"}
                  onClick={() => {
                    setCurrentPosition(currentPosition + 1)
                  }}
                />
              </Flex>
            )}
        </Container>
      </Box>
    </>
  )
}

export const query = graphql`
  query VerticalMarketQuery($handle: String!) {
    page: datoCmsVerticalMarket(url: { eq: $handle }) {
      url
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      seoKeywords
      heroTitle
      heroSubtitle
      heroMedia {
        url
      }
      projects {
        url
        image {
          url
        }
        title
        year
        subtitle
        projectCategories {
          name
        }
        branch {
          name
        }
      }
    }

    projectCategories: allDatoCmsProjectCategory {
      nodes {
        name
      }
    }

    branches: allDatoCmsBranch {
      nodes {
        name
      }
    }
  }
`

const withContext = Component => {
  return props => {
    return (
      <ActiveFilterProvider>
        <Component {...props} />
      </ActiveFilterProvider>
    )
  }
}

export default withContext(VerticalMarketsPage)
